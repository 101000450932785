/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WelcomeScreen_currentMetaData = {
    readonly year: number | null;
    readonly startDate: string;
    readonly endDate: string;
    readonly " $fragmentRefs": FragmentRefs<"Countdown_currentMetaData">;
    readonly " $refType": "WelcomeScreen_currentMetaData";
};
export type WelcomeScreen_currentMetaData$data = WelcomeScreen_currentMetaData;
export type WelcomeScreen_currentMetaData$key = {
    readonly " $data"?: WelcomeScreen_currentMetaData$data;
    readonly " $fragmentRefs": FragmentRefs<"WelcomeScreen_currentMetaData">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WelcomeScreen_currentMetaData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "year",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Countdown_currentMetaData"
    }
  ],
  "type": "MetaData",
  "abstractKey": null
};
(node as any).hash = '26f95bb12c6bdfba9c32ac6e49d0203b';
export default node;
