/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type pages_index_QueryVariables = {
    slugs: Array<string>;
};
export type pages_index_QueryResponse = {
    readonly currentMetaData: {
        readonly id: string;
        readonly interestForm: string | null;
        readonly collaborators: ReadonlyArray<{
            readonly id: string;
        }> | null;
        readonly companiesFirstDay: ReadonlyArray<{
            readonly id: string;
        }> | null;
        readonly companiesLastDay: ReadonlyArray<{
            readonly id: string;
        }> | null;
        readonly mainCollaborator: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"MainCollaborator_company">;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"Year_currentMetaData" | "WelcomeScreen_currentMetaData" | "Companies_query" | "Collaborators_query">;
    };
    readonly pages: ReadonlyArray<{
        readonly title: string;
        readonly slug: string;
        readonly ingress: string;
        readonly " $fragmentRefs": FragmentRefs<"PageView_page">;
    } | null> | null;
};
export type pages_index_Query = {
    readonly response: pages_index_QueryResponse;
    readonly variables: pages_index_QueryVariables;
};



/*
query pages_index_Query(
  $slugs: [String!]!
) {
  currentMetaData {
    ...Year_currentMetaData
    ...WelcomeScreen_currentMetaData
    id
    interestForm
    collaborators {
      id
    }
    companiesFirstDay {
      id
    }
    companiesLastDay {
      id
    }
    mainCollaborator {
      id
      ...MainCollaborator_company
    }
    ...Companies_query
    ...Collaborators_query
  }
  pages(slugs: $slugs) {
    ...PageView_page
    title
    slug
    ingress
    id
  }
}

fragment Collaborator_company on Company {
  id
  name
  logo(width: 459, height: 170)
  url
  description
}

fragment Collaborators_query on MetaData {
  collaborators {
    id
    ...Collaborator_company
  }
}

fragment Companies_query on MetaData {
  companiesFirstDay {
    id
    ...CompanyView_company
  }
  companiesLastDay {
    id
    ...CompanyView_company
  }
}

fragment CompanyView_company on Company {
  id
  name
  logo(width: 240, height: 200)
  url
}

fragment Countdown_currentMetaData on MetaData {
  startDate
}

fragment MainCollaborator_company on MainCollaborator {
  id
  name
  logo(width: 1000, height: 1000)
  url
  description
  video
  poster
  intro
}

fragment PageView_page on Page {
  id
  content
  slug
  title
  dateSaved
  dateCreated
  videoFile
}

fragment WelcomeScreen_currentMetaData on MetaData {
  year
  startDate
  ...Countdown_currentMetaData
  endDate
}

fragment Year_currentMetaData on MetaData {
  year
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slugs"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interestForm",
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = [
  {
    "kind": "Variable",
    "name": "slugs",
    "variableName": "slugs"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ingress",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = [
  (v1/*: any*/),
  (v8/*: any*/),
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "height",
        "value": 200
      },
      {
        "kind": "Literal",
        "name": "width",
        "value": 240
      }
    ],
    "kind": "ScalarField",
    "name": "logo",
    "storageKey": "logo(height:200,width:240)"
  },
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pages_index_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetaData",
        "kind": "LinkedField",
        "name": "currentMetaData",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "collaborators",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "companiesFirstDay",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "companiesLastDay",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MainCollaborator",
            "kind": "LinkedField",
            "name": "mainCollaborator",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MainCollaborator_company"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Year_currentMetaData"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WelcomeScreen_currentMetaData"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Companies_query"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Collaborators_query"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "pages",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PageView_page"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pages_index_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetaData",
        "kind": "LinkedField",
        "name": "currentMetaData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "collaborators",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "height",
                    "value": 170
                  },
                  {
                    "kind": "Literal",
                    "name": "width",
                    "value": 459
                  }
                ],
                "kind": "ScalarField",
                "name": "logo",
                "storageKey": "logo(height:170,width:459)"
              },
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "companiesFirstDay",
            "plural": true,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "companiesLastDay",
            "plural": true,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MainCollaborator",
            "kind": "LinkedField",
            "name": "mainCollaborator",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "height",
                    "value": 1000
                  },
                  {
                    "kind": "Literal",
                    "name": "width",
                    "value": 1000
                  }
                ],
                "kind": "ScalarField",
                "name": "logo",
                "storageKey": "logo(height:1000,width:1000)"
              },
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "video",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "poster",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "intro",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "pages",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          (v6/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateSaved",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "videoFile",
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29db92b197a914a837d3214b8c52518a",
    "id": null,
    "metadata": {},
    "name": "pages_index_Query",
    "operationKind": "query",
    "text": "query pages_index_Query(\n  $slugs: [String!]!\n) {\n  currentMetaData {\n    ...Year_currentMetaData\n    ...WelcomeScreen_currentMetaData\n    id\n    interestForm\n    collaborators {\n      id\n    }\n    companiesFirstDay {\n      id\n    }\n    companiesLastDay {\n      id\n    }\n    mainCollaborator {\n      id\n      ...MainCollaborator_company\n    }\n    ...Companies_query\n    ...Collaborators_query\n  }\n  pages(slugs: $slugs) {\n    ...PageView_page\n    title\n    slug\n    ingress\n    id\n  }\n}\n\nfragment Collaborator_company on Company {\n  id\n  name\n  logo(width: 459, height: 170)\n  url\n  description\n}\n\nfragment Collaborators_query on MetaData {\n  collaborators {\n    id\n    ...Collaborator_company\n  }\n}\n\nfragment Companies_query on MetaData {\n  companiesFirstDay {\n    id\n    ...CompanyView_company\n  }\n  companiesLastDay {\n    id\n    ...CompanyView_company\n  }\n}\n\nfragment CompanyView_company on Company {\n  id\n  name\n  logo(width: 240, height: 200)\n  url\n}\n\nfragment Countdown_currentMetaData on MetaData {\n  startDate\n}\n\nfragment MainCollaborator_company on MainCollaborator {\n  id\n  name\n  logo(width: 1000, height: 1000)\n  url\n  description\n  video\n  poster\n  intro\n}\n\nfragment PageView_page on Page {\n  id\n  content\n  slug\n  title\n  dateSaved\n  dateCreated\n  videoFile\n}\n\nfragment WelcomeScreen_currentMetaData on MetaData {\n  year\n  startDate\n  ...Countdown_currentMetaData\n  endDate\n}\n\nfragment Year_currentMetaData on MetaData {\n  year\n}\n"
  }
};
})();
(node as any).hash = '24ec5ed907db6af357073028af4642f9';
export default node;
