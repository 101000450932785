/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CompanyView_company = {
    readonly id: string;
    readonly name: string;
    readonly logo: string | null;
    readonly url: string | null;
    readonly " $refType": "CompanyView_company";
};
export type CompanyView_company$data = CompanyView_company;
export type CompanyView_company$key = {
    readonly " $data"?: CompanyView_company$data;
    readonly " $fragmentRefs": FragmentRefs<"CompanyView_company">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanyView_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 200
        },
        {
          "kind": "Literal",
          "name": "width",
          "value": 240
        }
      ],
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": "logo(height:200,width:240)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
(node as any).hash = 'd35d73079202d94cdd70e3da465adfa7';
export default node;
