/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Companies_query = {
    readonly companiesFirstDay: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"CompanyView_company">;
    }> | null;
    readonly companiesLastDay: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"CompanyView_company">;
    }> | null;
    readonly " $refType": "Companies_query";
};
export type Companies_query$data = Companies_query;
export type Companies_query$key = {
    readonly " $data"?: Companies_query$data;
    readonly " $fragmentRefs": FragmentRefs<"Companies_query">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CompanyView_company"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Companies_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "companiesFirstDay",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "companiesLastDay",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "MetaData",
  "abstractKey": null
};
})();
(node as any).hash = 'f1fdf2ade8d9c80d1b2c65775f18807b';
export default node;
