/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Countdown_currentMetaData = {
    readonly startDate: string;
    readonly " $refType": "Countdown_currentMetaData";
};
export type Countdown_currentMetaData$data = Countdown_currentMetaData;
export type Countdown_currentMetaData$key = {
    readonly " $data"?: Countdown_currentMetaData$data;
    readonly " $fragmentRefs": FragmentRefs<"Countdown_currentMetaData">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Countdown_currentMetaData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    }
  ],
  "type": "MetaData",
  "abstractKey": null
};
(node as any).hash = '15df613c9c9b1c401af0d02d7393b963';
export default node;
