/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Collaborator_company = {
    readonly id: string;
    readonly name: string;
    readonly logo: string | null;
    readonly url: string | null;
    readonly description: string;
    readonly " $refType": "Collaborator_company";
};
export type Collaborator_company$data = Collaborator_company;
export type Collaborator_company$key = {
    readonly " $data"?: Collaborator_company$data;
    readonly " $fragmentRefs": FragmentRefs<"Collaborator_company">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Collaborator_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 170
        },
        {
          "kind": "Literal",
          "name": "width",
          "value": 459
        }
      ],
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": "logo(height:170,width:459)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
(node as any).hash = '903bf2a86f408ce65c1d1e79f82621bd';
export default node;
