/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Collaborators_query = {
    readonly collaborators: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Collaborator_company">;
    }> | null;
    readonly " $refType": "Collaborators_query";
};
export type Collaborators_query$data = Collaborators_query;
export type Collaborators_query$key = {
    readonly " $data"?: Collaborators_query$data;
    readonly " $fragmentRefs": FragmentRefs<"Collaborators_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Collaborators_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "collaborators",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Collaborator_company"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MetaData",
  "abstractKey": null
};
(node as any).hash = '77643d8858e016897c8ec3d719b7fcb2';
export default node;
