/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MainCollaborator_company = {
    readonly id: string;
    readonly name: string;
    readonly logo: string | null;
    readonly url: string | null;
    readonly description: string;
    readonly video: string | null;
    readonly poster: string | null;
    readonly intro: string | null;
    readonly " $refType": "MainCollaborator_company";
};
export type MainCollaborator_company$data = MainCollaborator_company;
export type MainCollaborator_company$key = {
    readonly " $data"?: MainCollaborator_company$data;
    readonly " $fragmentRefs": FragmentRefs<"MainCollaborator_company">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainCollaborator_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 1000
        },
        {
          "kind": "Literal",
          "name": "width",
          "value": 1000
        }
      ],
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": "logo(height:1000,width:1000)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "video",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "poster",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "intro",
      "storageKey": null
    }
  ],
  "type": "MainCollaborator",
  "abstractKey": null
};
(node as any).hash = 'f470b8ba4726f355507044d482dd9e2a';
export default node;
